body {
  background-color: #77A9C2;
  color: #000; }

.row {
  margin-left: 0px;
  margin-right: 0px; }

.cus-outline {
  outline: 1px solid #67819C; }

.cus-outline-left-top {
  border-left: 1px solid #67819C; }

.padding-15px {
  padding: 15px; }

.cus-background {
  background-color: #BEDAE6; }

.cus-background-b {
  background-color: #BEDAE6; }

.img-100 {
  width: 100%; }

a {
  color: #000;
  text-decoration: none; }

.mg-left-20 {
  margin-left: -20px; }

.navbar-default {
  background-color: #2e3237;
  border-color: #F0F0F0;
  position: relative;
  min-height: 50px;
  margin-bottom: 0px;
  border: 1px solid transparent; }
  .navbar-default .navbar-header .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 17px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid white;
    border-radius: 4px; }
    .navbar-default .navbar-header .navbar-toggle:hover, .navbar-default .navbar-header .navbar-toggle:focus {
      background-color: #C3D0E1;
      color: white; }
    .navbar-default .navbar-header .navbar-toggle .icon-bar {
      background-color: #FFF; }
  .navbar-default .navbar-collapse {
    color: white;
    text-align: center; }
    .navbar-default .navbar-collapse .navbar-nav > li > a {
      transition: .6s;
      color: #FFFFFF;
      text-transform: uppercase;
      font-size: 15px;
      position: relative;
      display: block;
      padding: 22px 5px; }
      .navbar-default .navbar-collapse .navbar-nav > li > a:hover, .navbar-default .navbar-collapse .navbar-nav > li > a a:focus {
        color: #77A9C2;
        background-color: transparent; }
    .navbar-default .navbar-collapse .navbar-right {
      font-family: "Palatino Linotype", "Book Antiqua", Palatino, serif; }

@media (min-width: 768px) {
  .container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0; } }
.main-header {
  margin-top: 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center; }

#banner img {
  display: block;
  border: 0;
  margin-left: auto;
  margin-right: auto; }

@media (max-width: 768px) {
  #banner .col-xs-6 {
    width: 100%; } }
.contact-box {
  position: absolute;
  text-align: center;
  border-radius: 5px;
  display: block;
  width: 327px;
  background-color: #BEDAE6;
  border: 1px solid #BEDAE6;
  z-index: 6;
  margin-left: 66px;
  margin-top: 20px; }
  @media (max-width: 731px) {
    .contact-box {
      width: 237px;
      margin-left: 0px;
      left: 15%; } }
  @media (max-width: 584px) {
    .contact-box {
      width: 237px;
      left: 7%; } }
  @media (max-width: 497px) {
    .contact-box {
      width: 180px;
      left: 11%; } }
  @media (max-width: 399px) {
    .contact-box {
      display: none; } }

.contact-info {
  font-size: 20px; }
  @media (max-width: 731px) {
    .contact-info {
      font-size: 16px; } }
  @media (max-width: 320px) {
    .contact-info {
      font-size: 17px; } }
  @media (max-width: 497px) {
    .contact-info {
      font-size: 14px; }
      .contact-info h1 {
        font-size: 26px; } }
  @media (max-width: 399px) {
    .contact-info {
      font-size: 14px; }
      .contact-info h1 {
        font-size: 21px; } }

@media (min-width: 400px) {
  .mobile-contact-box {
    display: none; } }

.footer {
  text-align: center; }

.tel p {
  text-align: center;
  font-size: 20px; }

.center-text {
  text-align: center; }

/*Password and Subscription includes beginning */
.content-box {
  margin: 0 auto;
  width: 72%; }

.center-header {
  text-align: center; }

#no-pad {
  padding-top: 0px; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

input[type="submit"] {
  margin-left: 0px;
  display: block;
  -webkit-appearance: button;
  cursor: pointer;
  margin-right: auto; }

@media (min-width: 1200px) {
  #no-pad {
    padding-top: 7px; } }
@media (min-width: 768px) {
  .form-horizontal .control-label {
    font-size: 14px;
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right; }

  .form-control {
    width: 90%; } }
/*Password and Subscription includes end */
.header-text {
  text-align: center; }

.contact-btn span {
  border-radius: 21px;
  padding: 15px;
  font-size: 24px;
  background-color: #e5e5e8; }

.contact-btn {
  display: block;
  text-align: center;
  margin-bottom: 25px;
  margin-top: 15px; }

.row {
  margin-left: 0px;
  margin-right: 0px; }

.request_form input {
  margin-bottom: 4px; }

p.message {
  text-transform: uppercase;
  margin-top: 18px;
  color: green;
  font-size: 29px;
  text-align: center; }

@media (max-width: 500px) {
  form input[type="text"], input[type="submit"] {
    width: 86%;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; } }
.errorsix {
  display: none;
  color: red; }

.errorfour {
  display: none;
  color: red; }

span#xfour, span#xsix {
  color: red;
  font-size: 18px; }

span#checkmarksix, span#checkmarkfour {
  color: green;
  font-size: 18px; }

input[type="text"] {
  margin-bottom: 0px;
  width: 226px; }
  @media (max-width: 300px) {
    input[type="text"] {
      margin-bottom: 0px;
      width: 86%; } }

input[type="submit"] {
  color: black;
  margin-top: 10px; }
  @media (max-width: 350px) {
    input[type="submit"] {
      font-size: 18px; } }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

@media (max-width: 350px) {
  input#ccfour, input#ccsix {
    margin-bottom: 0px;
    width: 86%; } }
p.error {
  color: red; }
